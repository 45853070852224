import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "../styles/career.scss"

import CareerHeader from "../components/CareerHeader"
import CareerFirst from "../components/CareerFirst"
import CareerSecond from "../components/CareerSecond"
import News from "../components/News"
import axios from 'axios'

const Career = ({ data }) => {
  const [career, setCareerData] = useState([])

  useEffect(() => {
    axios.get('https://staging.visiomate.com/jobpositions').then((res) => {
      setCareerData([...JSON.parse(res.data.result).data]);
    })
  }, []);
  return (
    <Layout pageTitle={"Visiomate - Career"}
    descrip = {"Join a team that values innovation, collaboration, and work-life balance. Discover fulfilling career opportunities at Visiomate. Apply now!"}>
      <CareerHeader data={data} />
      <CareerFirst />
      <CareerSecond
        careerData={[
          ...career
        ]}
      />
      <News isAbout={true} career={true} />
    </Layout>
  )
}

export default Career
export const query = graphql`
  query CareerImages {

    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careerBg: file(relativePath: { eq: "lifeatvisiomatebg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`
